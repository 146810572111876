import axios from "axios";
import config from "@/config";

const API_URL = config.apiUrl + config.apiUrlPrefix;

class SMSService {

    getAllSettings(data) {
        return axios({
            method: 'GET',
            url: `${API_URL}/sms`,
            params: data
        });
    }


    sendSMS(data) {
        return axios({
            method: 'POST',
            url: `${API_URL}/sms`,
            data: data
        });
    }


}

export default new SMSService();