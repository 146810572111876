<template>
    <div class="modal fade" tabindex="-1">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">SMS</h5>
                <button type="button" class="btn-close" @click="cancelModal()" aria-label="Close"></button>
            </div>
            <div class="modal-body">
                <div class="mb-3">
                    <label for="phoneNumber" class="form-label">Broj telefona</label>
                    <input type="text" class="form-control" id="phoneNumber" v-model="phoneNumber" placeholder="+3816412345">
                </div>
                <div class="mb-3">
                    <label for="textContent" class="form-label">Poruka</label>
                    <textarea class="form-control" id="textContent" rows="3" v-model="message"></textarea>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" @click="cancelModal()">Zatvori</button>
                <button type="button" class="btn btn-primary" @click="confirmModal()">Posalji SMS</button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Modal } from 'bootstrap';
    import SMSService from '@/service/SMSService.js';


    export default {

        props: {

        },

        data() {
            return {
                modal: null,
                phoneNumber: "",
                message: ""
            }
        },
        
        methods: {

            confirmModal(data) {
                this.sendSMS()
                this.hideModal()
                this.$emit('onConfirm', data);
            },

            cancelModal(data) {
                this.hideModal()
                this.$emit('onCancel', data);
            },

            onModalShown() {
                
            },

         

            showModal() {
                this.modal.show();
            },
            hideModal() {
                this.modal.hide();
            },

            setUpListener() {    
                let myModalEl = this.$el
                this.modal = new Modal(myModalEl, {keyboard: false});

                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.onModalShown();
                })
            },

            async sendSMS() {
                let data = {
                    "phone_number": this.phoneNumber,
                    "message": this.message
                }

                try {
                    await SMSService.sendSMS(data)
                    this.$toasted.show("Poslato", { 
                        type: 'success',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });

                } catch {
                    this.$toasted.show("Neuspešno slanje", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }

            }
        },

        mounted() {
            this.setUpListener();
        },

    }
</script>

<style>

</style>
