
<template>
<div class="card mb-3">
    <modalSendSMS ref="modalSendSms" />
    <div class="card-body">
        <h4>SMS Servis</h4>
        <hr>

        <div v-if="settings_data">
            <div class="settings-group">

                <div class="global-settings-parameter option-field input-group">
                    <p class="setting-desc">Mesečni limit za slanje SMS Poruka</p>
                <div class="settings-value">
                        <input type="number" min="0" max="99999" step="1" class="form-control" v-model="settings_data.sms_month_limit" name="sms-month-limit" id="sms-month-limit">
                    </div>
                </div>




            </div>

            <div class="p-3">
                <p>Poslato <strong>{{ month_sent_sms }}</strong> SMS poruke ovaj mesec</p>
            </div>

            <div class="p-3">
                <button @click="openSMSModal" class="btn btn-secondary">Posalji SMS</button>
            </div>

            <button @click="saveSettings" class="btn btn-primary">Sačuvaj</button>

        </div>

    </div>
</div>
</template>

<script>

    import SettingsService from '@/service/SettingsService.js';
    import {mapState} from 'vuex'
    import ModalSendSMS from '../modals/sms/ModalSendSMS.vue';

    export default {
        
        name: "SettingsGeneral",

        components: {
            ModalSendSMS,
        },

        data() {
            return {
                // settings_data: {
                //     sms_month_limit: 200,
                // },

                month_sent_sms: "?",

                settings_data: null

            }
        },

        computed: {
            ...mapState([
                'settings',
            ]),

        },


        methods: {
            async loadSettingsData(){
                await this.$store.dispatch('loadSettings');
                console.log(this.settings);

                let settings_data = {}
                this.settings.forEach(opt => {
                    if (opt.section != 'sms') return

                    settings_data[opt.name] = opt.value
                });

                this.settings_data = settings_data
            },

            async saveSettings() {
                let payload = {}

                Object.entries(this.settings_data).forEach(entry => {
                    if (!isNaN(entry[1])) {
                        entry[1] = parseFloat(entry[1]) 
                    }

                    payload["sms__" + entry[0]] = entry[1]
                })

                try {
                    await SettingsService.updateSettings(payload);
                    this.$toasted.show("Sačuvano", { 
                        type: 'success',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });

                } catch {
                    this.$toasted.show("Neuspešno čuvanje", { 
                        type: 'error',
                        theme: "toasted-primary", 
                        position: "top-center", 
                        duration : 3000
                    });
                }





            },

            openSMSModal() {
                this.$refs.modalSendSms.showModal();
            }
        },

        created() {
            this.loadSettingsData();
        },

    }
</script>
